import { navigate } from 'gatsby-link';
import React, { useEffect } from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';

const NotFoundPage = () => {
  useEffect(() => {
    // navigate('/');
  }, []);

  const sendToHome = () => {
    navigate('/');
  };

  return (
    <Layout>
      <Seo title="404: Pagină inexsitentă" />
      <div
        style={{ width: '100%', height: '100vh', backgroundColor: '#FCFAF1' }}
      >
        <div class="fixed-center">
          <h1 style={{ textAlign: 'center' }}>404</h1>
          <h2 style={{ textAlign: 'center' }}>Pagină inexistentă!</h2>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button className="not-found-btn" onClick={() => sendToHome()}>
              <h4
                style={{
                  margin: '5px',
                }}
              >
                Înapoi la pagina principală
              </h4>
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
